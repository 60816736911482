import { FieldRegistryConfig } from "../../types";
import { DateFieldControl } from "./DateFieldControl/DateFieldControl";
import { dateToDateValue } from "@smartsuite/fields-logic/lib/utils/date";

export interface DateFieldValue {
  date: string | null;
  include_time: boolean;
}

export const dateFieldConfig: FieldRegistryConfig<DateFieldValue> = {
  icon: "date-select",
  control: (props) => <DateFieldControl {...props} />,
  convertFormValueToApi: (value) =>
    value.include_time
      ? {
          ...value,
          date: value.date ? new Date(value.date).toISOString() : null,
        }
      : dateToDateValue(new Date(value.date ?? ""), value.include_time),
  prefill: ({ value, field, config }) => {
    try {
      const iso8601Regex =
        /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
      if (!iso8601Regex.test(value)) return null;

      const dateField = config?.form_state.items.find((item) => item.slug === field.slug);
      const includeTime = dateField?.params.include_time;

      const date = new Date(value);
      return {
        date,
        include_time: includeTime,
      };
    } catch (error) {
      return null;
    }
  },
};
