import React from "react";
import { useFormContext } from "../hooks/useFormContext";

import { FormConfigApplicationStructureItem, FormConfigItem } from "../types/form";
import { getFieldRegistryConfig } from "./map";

export interface FieldControlComponentProps {
  formItem: FormConfigItem;
  field: FormConfigApplicationStructureItem;
  isItemConditionSatisfied: boolean;
}

export const FieldControlComponent: React.FC<FieldControlComponentProps> = ({
  formItem,
  field,
  isItemConditionSatisfied,
}) => {
  const context = useFormContext();
  const registryConfig = getFieldRegistryConfig(field.field_type);
  const label = formItem.params?.label ? formItem.params?.label : field.label;

  const ControlComponent = registryConfig?.control;
  if (!ControlComponent) {
    console.error(`ControlComponent not found for type "${field.field_type}"`);
    return null;
  }

  return (
    <ControlComponent
      isItemConditionSatisfied={isItemConditionSatisfied}
      caption={formItem.params?.caption}
      context={context}
      field={field}
      formItem={formItem}
      label={label}
      name={formItem.slug}
      required={formItem.required}
    />
  );
};
